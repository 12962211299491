import { DatePickerCalendarSelectState } from '../../../modules/calendar/webComponents/DatePickerCalendar';
import DateFieldManager from './DateFieldManager';

export default class DepartureDateFieldManager extends DateFieldManager {
    public calendarSelectState: DatePickerCalendarSelectState = DatePickerCalendarSelectState.END;

    protected get date(): Date | null {
        return this.travelPeriodStateManager.checkOutDate;
    }

    protected readonly handleClearEvent = (): void => {
        this.travelPeriodStateManager.updateCheckOut(null);
        this.travelPeriodUIManager.updateTravelPeriodInputValue();
    };
}
