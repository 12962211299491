import type {
    Observer,
    Subject
} from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import type DatePickerCalendar from '../../../modules/calendar/webComponents/DatePickerCalendar';
import type { DatePickerCalendarSelectState } from '../../../modules/calendar/webComponents/DatePickerCalendar';
import TravelPeriodStateManager from './TravelPeriodStateManager';

export default class CalendarManager implements Observer {
    readonly #travelPeriodStateManager: TravelPeriodStateManager;

    #calendar: DatePickerCalendar | null = null;

    public set calendar(calendar: DatePickerCalendar | null) {
        this.#calendar = calendar;
    }

    public constructor() {
        this.#travelPeriodStateManager = TravelPeriodStateManager.getInstance();
        this.#travelPeriodStateManager.attach(this);
    }

    public update(subject: Subject): void {
        if (!(subject instanceof TravelPeriodStateManager) || this.#calendar === null) {
            return;
        }

        const { checkInDate, checkOutDate } = this.#travelPeriodStateManager;

        this.#calendar.selectedDateRange = {
            ...this.#calendar.selectedDateRange,
            start: checkInDate,
            end: checkOutDate
        };
    }

    public setCalenderSelectState(state: DatePickerCalendarSelectState | null): void {
        if (this.#calendar === null) {
            return;
        }

        this.#calendar.selectState = state;
    }
}
