import AbstractSubject from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import { isDateValid } from '@naturehouse/nh-essentials/lib/dates/date';

export enum FlexibleTravelPeriodType {
    WEEKEND = 'weekend',
    LONG_WEEKEND = 'long_weekend',
    MIDWEEK = 'midweek'
}

export default class TravelPeriodStateManager extends AbstractSubject {
    static #instance: TravelPeriodStateManager | null = null;

    public static getInstance(): TravelPeriodStateManager {
        if (this.#instance === null) {
            this.#instance = new TravelPeriodStateManager();
        }

        return this.#instance;
    }

    public static destroyInstance(): void {
        this.#instance = null;
    }

    private constructor(
        public flexibleTravelPeriod: FlexibleTravelPeriodType | null = null,
        public flexibleTravelPeriodMonths: number[] = []
    ) {
        super();
    }

    #checkInDate: Date | null = null;

    get checkInDate(): Date | null {
        return this.#checkInDate;
    }

    set checkInDate(checkInDate: Date | null) {
        if (checkInDate === null || !isDateValid(new Date(checkInDate))) {
            this.#checkInDate = null;
            return;
        }

        this.#checkInDate = checkInDate;
    }

    #checkOutDate: Date | null = null;

    get checkOutDate(): Date | null {
        return this.#checkOutDate;
    }

    set checkOutDate(checkOutDate: Date | null) {
        if (checkOutDate === null || !isDateValid(new Date(checkOutDate))) {
            this.#checkOutDate = null;
            return;
        }

        this.#checkOutDate = checkOutDate;
    }

    public updateCheckIn(date: Date | null): void {
        this.checkInDate = date;
        this.notify();
    }

    public updateCheckOut(date: Date | null): void {
        this.checkOutDate = date;
        this.notify();
    }

    public updateFlexibleTravelPeriod(travelPeriod: FlexibleTravelPeriodType | null): void {
        this.flexibleTravelPeriod = travelPeriod;
        this.notify();
    }

    public updateFlexibleTravelPeriodMonths(months: number[]): void {
        this.flexibleTravelPeriodMonths = months;
        this.notify();
    }

    public resetDates(): void {
        this.checkInDate = null;
        this.checkOutDate = null;
        this.notify();
    }

    public resetFlexibleTravelPeriod(): void {
        this.flexibleTravelPeriod = null;
        this.flexibleTravelPeriodMonths = [];
        this.notify();
    }
}
