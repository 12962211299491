import type {
    Observer,
    Subject
} from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import type CalendarWeekDays from '@naturehouse/design-system/components/molecules/calendar-week-days/CalendarWeekDays';
import StayDurationTypeState, {
    StayDurationType
} from '../../../modules/search/store/StayDurationTypeState';

export default class WeekDaysManager implements Observer {
    #weekDays: CalendarWeekDays | null = null;

    public set weekDays(weekDays: CalendarWeekDays | null) {
        this.#weekDays = weekDays;
    }

    public constructor() {
        StayDurationTypeState.getInstance().attach(this);
    }

    public update(subject: Subject): void {
        if (this.#weekDays === null) {
            return;
        }

        if (subject instanceof StayDurationTypeState) {
            const { type } = subject;
            this.#weekDays.toggleAttribute('hidden', type === StayDurationType.FLEXIBLE);
        }
    }
}
