import { createPopper, Instance } from '@popperjs/core';
import getComputedStyle from '@popperjs/core/lib/dom-utils/getComputedStyle';
import { Placement } from '@popperjs/core/lib/enums';
import iconTemplate from '@naturehouse/design-system/components/protons/icon/Icon.html.njk';
import {
    matchMediaAddEventListener,
    matchMediaRemoveEventListener
} from '@naturehouse/nh-essentials/lib/polyfills/matchMedia';
import parseStringAsHtml from '@naturehouse/nh-essentials/lib/html/parser';
import '../../../../css/elements/_modal.pcss';

class Modal extends HTMLElement {
    readonly #placement: Placement = 'bottom-start';

    readonly #fromTabletMediaQuery: MediaQueryList;

    readonly #options: ModalOptions | undefined;

    public isOpen = false;

    #popper: Instance | undefined;

    #handleResize: (() => void) | null = null;

    #headerElement: HTMLElement | null = null;

    #contentElement: HTMLElement | null = null;

    #footerElement: HTMLElement | null = null;

    #bindToElement: HTMLElement | null = null;

    readonly #outsideClickListener = (event: Event): void => {
        const target = event.target as HTMLElement;
        if (this.#bindToElement === target) return;

        if (!this.contains(target)) {
            this.close();
        }
    };

    set header(content: HTMLElement | DocumentFragment) {
        if (!this.#headerElement) {
            return;
        }

        content.querySelector('[data-close]')?.addEventListener('click', this.close.bind(this));
        this.#headerElement.replaceWith(content);
    }

    get header(): HTMLElement | null {
        return this.#headerElement;
    }

    set content(content: HTMLElement | DocumentFragment) {
        if (!this.#contentElement) return;

        this.#contentElement.innerHTML = '';
        this.#contentElement.append(content);
    }

    get content(): HTMLElement {
        return <HTMLElement>this.#contentElement;
    }

    set footer(content: HTMLElement | DocumentFragment) {
        if (!this.#footerElement) return;

        this.#footerElement.innerHTML = '';
        this.#footerElement.append(content);
    }

    get footer(): HTMLElement {
        return <HTMLElement>this.#footerElement;
    }

    constructor(options?: ModalOptions) {
        super();

        this.#options = options;

        this.#render(this.#options);
        this.#addClasses(this.#options?.classList || []);
        this.toggleAttribute('hidden', this.#options?.hidden || true);

        const rootElement: HTMLElement = document.querySelector(':root') as HTMLElement;
        const computedStyle: CSSStyleDeclaration = getComputedStyle(rootElement);
        this.#fromTabletMediaQuery = window.matchMedia(computedStyle.getPropertyValue('--tablet'));
    }

    public open(options: ModalOpenOptions): void {
        this.isOpen = true;
        this.removeAttribute('hidden');

        this.#bindToElement = options.bindToElement;
        this.#positionModal(options.bindToElement);
        document.body.classList.add('nh-modal-open');
        this.#cleanupRepositionListener();

        this.#handleResize = (): void => {
            this.#positionModal(options.bindToElement);
        };

        document.addEventListener('click', this.#outsideClickListener, true);
        matchMediaAddEventListener(this.#fromTabletMediaQuery, this.#handleResize);
        this.dispatchEvent(new Event('open'));
    }

    public close(): void {
        this.dispatchEvent(new Event('close'));

        if (!this.#fromTabletMediaQuery.matches) {
            this.addEventListener(
                'animationend',
                () => {
                    this.setAttribute('hidden', '');
                    this.removeAttribute('closing');
                },
                { once: true }
            );

            this.setAttribute('closing', '');
        }

        this.#cleanupRepositionListener();
        document.body.classList.remove('nh-modal-open');
        document.removeEventListener('click', this.#outsideClickListener, true);

        if (this.#fromTabletMediaQuery.matches) {
            this.setAttribute('hidden', '');
        }

        this.#popper?.destroy();
        this.isOpen = false;
    }

    public get contentElement(): HTMLElement {
        const element = this.querySelector('.nh-modal__content') as HTMLElement | null;

        if (element === null) {
            throw Error('nh-modal error: content element not available');
        }

        return element;
    }

    protected connectedCallback(): void {
        this.querySelector('[data-close]')?.addEventListener('click', this.close.bind(this));
    }

    protected disconnectedCallback(): void {
        this.querySelector('[data-close]')?.removeEventListener('click', this.close.bind(this));

        this.#cleanupRepositionListener();
    }

    #positionModal(target: HTMLElement): void {
        if (!this.#fromTabletMediaQuery.matches) {
            this.removeAttribute('style');
            this.#popper?.destroy();
            return;
        }

        this.#create(target);
    }

    #create(target: HTMLElement): void {
        this.#popper = createPopper(target, this, {
            placement: this.#placement
        });
    }

    #cleanupRepositionListener(): void {
        if (this.#handleResize) {
            matchMediaRemoveEventListener(this.#fromTabletMediaQuery, this.#handleResize);
            this.#handleResize = null;
        }
    }

    #render(options?: ModalOptions): void {
        this.#renderHeader(options);
        this.#renderContentBlock();
        this.#renderFooterBlock();
    }

    #renderHeader(options?: ModalOptions): void {
        const headerElement = this.querySelector('header');

        if (
            options?.title === undefined ||
            (headerElement !== null && headerElement.parentElement === this)
        ) {
            this.#headerElement = headerElement;
            return;
        }

        const header = document.createElement('header');
        header.classList.add('nh-modal__header');

        const title = document.createElement('span');
        title.classList.add('h2');
        title.innerText = options.title;

        const icon = parseStringAsHtml(
            iconTemplate.render({
                name: 'close',
                size: '1.125'
            }),
            '.nh-icon'
        );

        const closeButton = document.createElement('button');
        closeButton.classList.add('nh-modal__close');
        closeButton.toggleAttribute('data-close', true);
        closeButton.type = 'button';
        closeButton.appendChild(icon);

        header.append(title, closeButton);

        this.#headerElement = header;

        this.insertBefore(header, this.firstChild);
    }

    #renderContentBlock(): void {
        const contentElement = this.querySelector('[data-content]') as HTMLElement | null;

        if (contentElement !== null && contentElement.parentElement === this) {
            this.#contentElement = contentElement;
            return;
        }

        const content = document.createElement('div');
        content.dataset.content = '';

        this.append(content);
        this.#contentElement = content;
    }

    #renderFooterBlock(): void {
        const element = this.querySelector('footer');

        if (element !== null && element.parentElement === this) {
            return;
        }

        const footer = document.createElement('footer');
        footer.classList.add('nh-modal__footer');
        this.append(footer);
        this.#footerElement = footer;
    }

    #addClasses(classList: string[]): void {
        classList.forEach((className) => {
            if (this.classList.contains(className)) {
                return;
            }

            this.classList.add(className);
        });
    }
}

if (!customElements.get('nh-modal')) {
    customElements.define('nh-modal', Modal);
}

export default Modal;
