import { isOfEnum } from '@naturehouse/nh-essentials/lib/utils/enum';
import parseStringAsHtml from '@naturehouse/nh-essentials/lib/html/parser';
import { getWeekDays, WeekDay } from '@naturehouse/nh-essentials/lib/dates/weekDays';
import dayOfWeekTemplate from '../../atoms/calendar-day-of-week/CalendarDayOfWeek.html.njk';
import './CalendarWeekDays.pcss';

export type CalendarWeekDaysProps = {
    startDayOfTheWeek: WeekDay;
};

export default class CalendarWeekDays extends HTMLOListElement {
    set startDayOfTheWeek(weekDay: WeekDay) {
        this.setAttribute('start-day-of-the-week', weekDay);
    }

    get startDayOfTheWeek(): WeekDay {
        const weekday = this.getAttribute('start-day-of-the-week') ?? '';
        if (isOfEnum(weekday, WeekDay)) {
            return weekday as WeekDay;
        }

        return WeekDay.MONDAY;
    }

    public constructor(startDayOfTheWeek?: WeekDay | undefined) {
        super();

        this.startDayOfTheWeek = startDayOfTheWeek ?? this.startDayOfTheWeek;

        this.#renderDaysOfWeek();
    }

    #renderDaysOfWeek(): void {
        for (const weekDay of getWeekDays(this.startDayOfTheWeek)) {
            const dayElement = parseStringAsHtml(
                dayOfWeekTemplate.render({ label: weekDay }),
                'li'
            );
            this.append(dayElement);
        }
    }
}

if (!customElements.get('nh-calendar-week-days')) {
    customElements.define('nh-calendar-week-days', CalendarWeekDays, { extends: 'ol' });
}
