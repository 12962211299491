import type DateFromTo from '@naturehouse/design-system/components/atoms/date-from-to/DateFromTo';
import TravelPeriodStateManager from './TravelPeriodStateManager';
import { formatDateForUrl } from '../../../util/dateHelper';

export default class TravelPeriodInputManager {
    #input: DateFromTo | null = null;

    public set input(input: DateFromTo | null) {
        this.#input = input;
    }

    public updateTravelPeriodInputValue(): void {
        if (!this.#input) {
            return;
        }

        const { checkInDate, checkOutDate } = TravelPeriodStateManager.getInstance();
        this.#input.value = JSON.stringify({
            start: checkInDate ? formatDateForUrl(checkInDate) : null,
            end: checkOutDate ? formatDateForUrl(checkOutDate) : null
        });
    }
}
