import './DateField.pcss';

export type DateFieldProps = {
    label: string;
    id: string;
    value?: string;
    placeholder?: string;
    focusPlaceholder?: string;
};

export default class DateField extends HTMLElement {
    readonly #input = this.querySelector('[data-role="date-field-input"]') as HTMLInputElement;

    get input(): HTMLInputElement {
        return this.#input;
    }

    readonly #clearButton = this.querySelector('[data-role="clear"]') as HTMLInputElement;

    #props: Partial<DateFieldProps> = {};

    public connectedCallback(): void {
        this.#props = {
            placeholder: this.#input.placeholder,
            focusPlaceholder: this.#input.dataset.focusPlaceholder
        };

        this.#handleInputChange();

        this.addEventListener('click', this.setInputFocus);
        this.#input.addEventListener('change', this.#handleInputChange);
        this.#input.addEventListener('input', this.#handleInputChange);
        this.#input.addEventListener('focus', this.#handleInputFocusIn);
        this.#input.addEventListener('focusin', this.#handleInputFocusIn);
        this.#input.addEventListener('focusout', this.#handleInputFocusOut);
        this.#clearButton.addEventListener('click', this.#handleClearButtonClick);
    }

    public disconnectedCallback(): void {
        this.removeEventListener('click', this.setInputFocus);
        this.#input.removeEventListener('change', this.#handleInputChange);
        this.#input.removeEventListener('input', this.#handleInputChange);
        this.#input.removeEventListener('focus', this.#handleInputFocusIn);
        this.#input.removeEventListener('focusin', this.#handleInputFocusIn);
        this.#input.removeEventListener('focusout', this.#handleInputFocusOut);
        this.#clearButton.removeEventListener('click', this.#handleClearButtonClick);
    }

    readonly #handleInputChange = (): void => {
        const hasInputValue = this.#input.value.length > 0;
        this.#toggleClearButtonHiddenAttribute(!hasInputValue);
        this.#toggleEmptyAttribute(!hasInputValue);
    };

    readonly setInputFocus = (): void => {
        this.#input.focus();
    };

    readonly #handleInputFocusIn = (): void => {
        if (!this.#props.focusPlaceholder) {
            return;
        }

        this.#input.placeholder = this.#props.focusPlaceholder;
    };

    readonly #handleInputFocusOut = (): void => {
        if (!this.#props.placeholder) {
            return;
        }

        this.#input.placeholder = this.#props.placeholder;
    };

    readonly #handleClearButtonClick = (): void => {
        this.#input.value = '';
        this.#input.dispatchEvent(new Event('change'));
        this.#toggleClearButtonHiddenAttribute(true);
        this.dispatchEvent(new Event('clear'));
    };

    readonly #toggleEmptyAttribute = (show: boolean): void => {
        this.toggleAttribute('empty', show);
    };

    readonly #toggleClearButtonHiddenAttribute = (force: boolean): void => {
        this.#clearButton.toggleAttribute('hidden', force);
    };
}

if (!customElements.get('nh-date-field')) {
    customElements.define('nh-date-field', DateField);
}
