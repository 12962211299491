import TravelPeriodStateManager from './TravelPeriodStateManager';

export default class DialogManager {
    public set dialog(dialog: Modal | null) {
        if (dialog === null) {
            return;
        }

        dialog.addEventListener('open', this.#handleOpenEvent);
    }

    readonly #handleOpenEvent = (): void => {
        TravelPeriodStateManager.getInstance().notify();
    };
}
