import type DateField from '@naturehouse/design-system/components/atoms/date-field/DateField';
import type {
    Observer,
    Subject
} from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import {
    FORMATS,
    intlDateFormat
} from '@naturehouse/nh-essentials/lib/dates/intlDateTimeFormatter';
import type { DatePickerCalendarSelectState } from '../../../modules/calendar/webComponents/DatePickerCalendar';
import StayDurationTypeState, {
    StayDurationType
} from '../../../modules/search/store/StayDurationTypeState';
import type { TravelPeriodUIManager } from './TravelPeriodUIManager';
import TravelPeriodStateManager from './TravelPeriodStateManager';

export default abstract class DateFieldManager implements Observer {
    protected readonly travelPeriodStateManager: TravelPeriodStateManager;

    protected abstract get date(): Date | null;

    public abstract calendarSelectState: DatePickerCalendarSelectState;

    #field: DateField | null = null;

    public set field(field: DateField | null) {
        if (field) {
            field.addEventListener('clear', this.handleClearEvent);
            field.addEventListener('click', this.#handleClickAndFocusEvent);
            field.addEventListener('focusin', this.#handleClickAndFocusEvent);
        }

        this.#field = field;
    }

    public constructor(protected readonly travelPeriodUIManager: TravelPeriodUIManager) {
        this.travelPeriodStateManager = TravelPeriodStateManager.getInstance();
        this.travelPeriodStateManager.attach(this);

        StayDurationTypeState.getInstance().attach(this);
    }

    protected abstract readonly handleClearEvent: () => void;

    public update(subject: Subject): void {
        if (this.#field === null) {
            return;
        }

        if (subject instanceof TravelPeriodStateManager) {
            this.#field.input.value = this.date
                ? intlDateFormat(this.date.toDateString(), FORMATS.SHORT_WITH_WEEKDAY)
                : '';
            this.#field.input.dispatchEvent(new Event('change'));
        }

        if (subject instanceof StayDurationTypeState) {
            const { type } = subject;
            this.#field.toggleAttribute('hidden', type === StayDurationType.FLEXIBLE);
        }
    }

    public setInputFocus(): void {
        if (!this.#field) {
            return;
        }

        this.#field.setInputFocus();
    }

    readonly #handleClickAndFocusEvent = (): void => {
        this.travelPeriodUIManager.setCalendarState(this.calendarSelectState);
    };
}
