import { DatePickerCalendarSelectState } from '../../../modules/calendar/webComponents/DatePickerCalendar';
import DateFieldManager from './DateFieldManager';

export default class ArrivalDateFieldManager extends DateFieldManager {
    public calendarSelectState: DatePickerCalendarSelectState = DatePickerCalendarSelectState.START;

    protected get date(): Date | null {
        return this.travelPeriodStateManager.checkInDate;
    }

    protected readonly handleClearEvent = (): void => {
        this.travelPeriodStateManager.resetDates();
        this.travelPeriodUIManager.updateTravelPeriodInputValue();
    };
}
