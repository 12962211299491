import { Observer, Subject } from '@naturehouse/nh-essentials/lib/architecture/ObserverPattern';
import type DateFromTo from '@naturehouse/design-system/components/atoms/date-from-to/DateFromTo';
import type DateField from '@naturehouse/design-system/components/atoms/date-field/DateField';
import type CalendarWeekDays from '@naturehouse/design-system/components/molecules/calendar-week-days/CalendarWeekDays';
import type DatePickerCalendar from '../../../modules/calendar/webComponents/DatePickerCalendar';
import { DatePickerCalendarSelectState } from '../../../modules/calendar/webComponents/DatePickerCalendar';
import ArrivalDateFieldManager from './ArrivalDateFieldManager';
import DepartureDateFieldManager from './DepartureDateFieldManager';
import TravelPeriodStateManager from './TravelPeriodStateManager';
import DialogManager from './DialogManager';
import CalendarManager from './CalendarManager';
import TravelPeriodInputManager from './TravelPeriodInputManager';
import WeekDaysManager from './WeekDaysManager';

export class TravelPeriodUIManager implements Observer {
    readonly #travelPeriodStateManager: TravelPeriodStateManager;

    readonly #arrivalDateFieldManager: ArrivalDateFieldManager;

    readonly #calendarManager: CalendarManager;

    readonly #departureDateFieldManager: DepartureDateFieldManager;

    readonly #dialogManager: DialogManager;

    readonly #travelPeriodInputManager: TravelPeriodInputManager;

    readonly #weekDaysManager: WeekDaysManager;

    public set arrivalDateField(arrivalDateField: DateField | null) {
        this.#arrivalDateFieldManager.field = arrivalDateField;
    }

    public set calendar(calendar: DatePickerCalendar | null) {
        this.#calendarManager.calendar = calendar;
    }

    public set departureDateField(departureDateField: DateField | null) {
        this.#departureDateFieldManager.field = departureDateField;
    }

    public set dialog(dialog: Modal | null) {
        this.#dialogManager.dialog = dialog;
    }

    public set travelPeriodInput(input: DateFromTo | null) {
        this.#travelPeriodInputManager.input = input;
    }

    public set weekDays(weekDays: CalendarWeekDays | null) {
        this.#weekDaysManager.weekDays = weekDays;
    }

    public constructor() {
        this.#travelPeriodStateManager = TravelPeriodStateManager.getInstance();
        this.#arrivalDateFieldManager = new ArrivalDateFieldManager(this);
        this.#calendarManager = new CalendarManager();
        this.#departureDateFieldManager = new DepartureDateFieldManager(this);
        this.#dialogManager = new DialogManager();
        this.#travelPeriodInputManager = new TravelPeriodInputManager();
        this.#weekDaysManager = new WeekDaysManager();
        this.#travelPeriodStateManager.attach(this);
    }

    public update(subject: Subject): void {
        if (!(subject instanceof TravelPeriodStateManager)) {
            return;
        }

        this.#setFocusStateOnDateField();
    }

    public setCalendarState(state: DatePickerCalendarSelectState | null): void {
        this.#calendarManager.setCalenderSelectState(state);
    }

    public updateTravelPeriodInputValue(): void {
        this.#travelPeriodInputManager.updateTravelPeriodInputValue();
    }

    public setInputFocusOnArrivalDateField(): void {
        this.#arrivalDateFieldManager.setInputFocus();
    }

    public setInputFocusOnDepartureDateField(): void {
        this.#departureDateFieldManager.setInputFocus();
    }

    #setFocusStateOnDateField(): void {
        if (this.#travelPeriodStateManager.checkInDate === null) {
            this.setInputFocusOnArrivalDateField();
            this.setCalendarState(DatePickerCalendarSelectState.START);
            return;
        }

        this.setInputFocusOnDepartureDateField();
        this.setCalendarState(DatePickerCalendarSelectState.END);
    }
}

export default new TravelPeriodUIManager();
